import { createRouter, createWebHistory } from 'vue-router'

import { useAuthStore } from '@/store/authStore';
import { useContactStore } from '@/store/contactStore';
import { useOwnerStore } from '@/store/ownerStore';
import { useNavBarStore } from "@/store/navBarStore";
import { useDynamicHeaderStore } from "@/store/dynamicHeaderStore";

import { ACCESS_LEVEL } from '@/dictionaries/contacts';
import { Auth } from 'aws-amplify';
import isEmpty from 'lodash.isempty';

import i18n from "../i18n";

import { dangerToast } from '@/helpers/toastHelpers';


const routes = [
  {
    path: '/:locale',
    component: {
      template: "<router-view></router-view>"
    },
    children: [

      // Resolve empty routes
      {
        path: '',
        redirect: '/location'
      },

      // Authentication and authorization
      {
        path: 'signUp',
        name: 'SignUp',
        component: () => import('@/views/auth/SignUpView.vue'),
        meta: {
          hideNavbar: true
        }
      },
      {
        path: 'signIn',
        name: 'SignIn',
        component: () => import('@/views/auth/SignInView.vue'),
        meta: {
          hideNavbar: true
        }
      },
      {
        path: 'forgotPassword',
        name: 'ForgotPassword',
        component: () => import('@/views/auth/ForgotPasswordView.vue'),
        meta: {
          hideNavbar: true
        }
      },

      // Account settings
      {
        path: 'account',
        name: 'Account',
        component: () => import('@/views/auth/AccountView.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'mfa',
        name: 'MFA',
        component: () => import('@/views/auth/MFAView.vue'),
        meta: {
          requiresAuth: true
        }
      },

      // Owner
      {
        path: 'owner',
        name: 'Owner',
        component: {
          template: "<router-view></router-view>"
        },
        redirect: { name: 'OwnerSettings' },
        children: [
          {
            path: 'settings',
            name: 'OwnerSettings',
            component: () => import('@/views/owner/OwnerSettings.vue'),
            meta: {
              requiresAuth: true,
              allowedAccessLevels: [ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN, ACCESS_LEVEL.LOCATION_MANAGER, ACCESS_LEVEL.LISTENER]
            }
          },
          {
            path: 'details/:id',
            name: 'OwnerDetails',
            component: () => import('@/views/owner/OwnerDetails.vue'),
            meta: {
              requiresAuth: true,
              allowedAccessLevels: [ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN]
            }
          },
          {
            path: 'contact/details/:email',
            name: 'ContactDetails',
            component: () => import('@/views/owner/ContactDetails.vue'),
            meta: {
              requiresAuth: true,
              allowedAccessLevels: [ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN]
            }
          },
          
        ]
      },

      // Playlist
      {
        path: ':mediaType/playlist',
        name: 'Playlist',
        component: {
          template: "<router-view></router-view>"
        },
        redirect: { name: 'PlaylistList' },
        children: [
          {
            path: 'list',
            name: 'PlaylistList',
            component: () => import('@/views/playlist/PlaylistList.vue'),
            meta: {
              requiresAuth: true,
              allowedAccessLevels: [ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN]
            },
          },
          {
            path: 'details/:SK?',
            name: 'PlaylistDetails',
            component: () => import('@/views/playlist/PlaylistDetails.vue'),
            meta: {
              requiresAuth: true,
              allowedAccessLevels: [ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN]
            }
          },
          {
            path: 'uploadTracks/:SK?',
            name: 'UploadTracks',
            component: () => import('@/views/playlist/UploadTracks'),
            meta: {
              requiresAuth: true,
              allowedAccessLevels: [ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN]
            }
          }
        ]
      },

      // Ether and connected entities
      {
        path: 'ether',
        name: 'Ether',
        component: {
          template: "<router-view></router-view>"
        },
        redirect: { name: 'Ethers' },
        children: [
          {
            path: 'list',
            name: 'Ethers',
            component: () => import('@/views/ether/EtherList.vue'),
            meta: {
              requiresAuth: true,
              allowedAccessLevels: [ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN]
            }
          },
          {
            path: 'details/:SK?',
            name: 'EtherDetails',
            component: () => import('@/views/ether/EtherDetails.vue'),
            meta: {
              requiresAuth: true,
              allowedAccessLevels: [ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN]
            }
          },
          {
            path: ':etherSK/schedule/:SK?',
            name: 'ScheduleDetails',
            component: () => import('@/views/ether/ScheduleDetails.vue'),
            meta: {
              requiresAuth: true,
              allowedAccessLevels: [ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN]
            }
          },
          {
            path: ':etherSK/schedule/:scheduleSK/timeslot/:SK?',
            name: 'TimeslotDetails',
            component: () => import('@/views/ether/TimeslotDetails.vue'),
            meta: {
              requiresAuth: true,
              allowedAccessLevels: [ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN]
            }
          },
        ]
      },

      // Locations
      {
        path: 'location',
        name: 'Location',
        component: {
          template: "<router-view></router-view>"
        },
        redirect: { name: 'LocationList' },
        children: [
          {
            path: 'list',
            name: 'LocationList',
            component: () => import('@/views/location/LocationList.vue'),
            meta: {
              requiresAuth: true,
              allowedAccessLevels: [ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN, ACCESS_LEVEL.LOCATION_MANAGER]
            }
          },

          {
            path: 'details/:SK?',
            name: 'LocationDetails',
            component: () => import('@/views/location/LocationDetails.vue'),
            meta: {
              requiresAuth: true,
              allowedAccessLevels: [ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN, ACCESS_LEVEL.LOCATION_MANAGER]
            }
          },

          {
            path: 'device/details/:hwid?',
            name: 'DeviceDetails',
            component: () => import('@/views/device/DeviceDetailsView.vue'),
            meta: {
              requiresAuth: true,
              allowedAccessLevels: [ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN, ACCESS_LEVEL.LOCATION_MANAGER]
            }
          },

          // Soloveyko-Browser
          // {
          //   path: 'soloveykoBrowser/:locationSK/:locationZone',
          //   name: 'SoloveykoBrowser',
          //   component: () => import('@/views/soloveykoBrowser/SoloveykoBrowser.vue'),
          //   meta: { 
          //     requiresAuth: true,
          //     allowedAccessLevels: [ACCESS_LEVEL.ADMIN, ACCESS_LEVEL.LISTENER]
          //   }
          // }
        ]
      },
      
      // AdCampaign
      {
        path: 'adCampaign',
        name: 'AdCampaign',
        component: {
          template: "<router-view></router-view>"
        },
        redirect: { name: 'AdCampaignList' },
        children: [
          {
            path: 'list',
            name: 'AdCampaignList',
            component: () => import('@/views/ad/AdCampaignList.vue'),
            meta: {
              requiresAuth: true,
              allowedAccessLevels: [ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN]
            }
          },
          {
            path: 'details/:SK?',
            name: 'AdCampaignDetails',
            component: () => import('@/views/ad/AdCampaignDetails.vue'),
            meta: {
              requiresAuth: true,
              allowedAccessLevels: [ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN]
            },
          }
        ]
      },

      // Browser
      {
        path: 'browser',
        name: 'Browser',
        component: {
          template: "<router-view></router-view>"
        },
        redirect: { name: 'BrowserScreenList' },
        children: [
          {
            path: 'list',
            name: 'BrowserScreenList',
            component: () => import('@/views/browser/BrowserScreenList.vue'),
            meta: {
              requiresAuth: true,
              allowedAccessLevels: [ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN, ACCESS_LEVEL.LOCATION_MANAGER]
            }
          },
          {
            path: 'details/:SK?',
            name: 'BrowserScreenDetails',
            component: () => import('@/views/browser/BrowserScreenDetails.vue'),
            meta: {
              requiresAuth: true,
              allowedAccessLevels: [ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN]
            }
          },
          {
            path: 'details/:SK/location/:locationSK',
            name: 'LocationBrowserScreen',
            component: () => import('@/views/browser/LocationBrowserScreen.vue'),
            meta: {
              requiresAuth: true,
              allowedAccessLevels: [ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN, ACCESS_LEVEL.LOCATION_MANAGER]
            },
          },
        ]
      },

      // Reports
      {
        path: 'report',
        name: 'Report',
        component: () => import('@/views/report/ReportView.vue'),
        meta: {
          requiresAuth: true,
          allowedAccessLevels: [ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN]
        }
      },

      // Soloveyko Browser
      {
        path: 'soloveykoBrowser',
        name: 'SoloveykoBrowser',
        component: () => import('@/views/soloveykoBrowser/SoloveykoBrowserView.vue'),
        meta: {
          requiresAuth: true,
          allowedAccessLevels: [ACCESS_LEVEL.GLORY_LORD, ACCESS_LEVEL.ADMIN, ACCESS_LEVEL.LISTENER]
        }
      }
    ]
  },

  // {
  //   path: '/browser/broadcast/:ownerId/:SK/:locationSK?',
  //   name: 'BrowserScreenBroadcast',
  //   component: () => import('@/views/browser/BrowserScreenBroadcast.vue'),
  //   meta: {
  //     hideNavbar: true,
  //     fullWidth: true
  //   }
  // },

  {
    path: '/browser/broadcast/:ownerId/:SK/:locationSK?',
    name: 'BrowserScreenBroadcast',
    component: () => import('@/views/browser/BrowserBroadcastSwitch.vue'),
    meta: {
      hideNavbar: true,
      fullWidth: true
    }
  },
  {
    path: '/browser/broadcast/test',
    name: 'BrowserScreenBroadcastTest',
    component: () => import('@/views/browser/fora/pages/ForaBase.vue'),
    meta: {
      hideNavbar: true,
      fullWidth: true
    }
  },

  {
    path: '/:pathMatch(.*)*',
    redirect: () => {
      return { path: `/${process.env.VUE_APP_I18N_LOCALE}/location`}
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "active",
  routes
})

router.beforeEach(async (to, from) => {

  // locale
  let paramsLocale = to.params.locale
  i18n.global.locale.value = to.params.locale
  // use locale if paramsLocale is not in SUPPORT_LOCALES
  if (!process.env.VUE_APP_I18N_SUPPORTED_LOCALE.includes(paramsLocale)) {
    paramsLocale = process.env.VUE_APP_I18N_LOCALE
  }

  // hide mobile navbar
  const navBarStore = useNavBarStore();
  navBarStore.hideMobileMenu();

  // clear headerPosition
  const dynamicHeaderStore = useDynamicHeaderStore();
  dynamicHeaderStore.clearAllPositions();

  // This is needed to preserve the selected tab when navigating within the same path
  if (to.path === from.path) {
    if (isEmpty(to.query) && !isEmpty(from.query)) {
      return {
        path: to.path,
        query: from.query
      }
    }
  }

  // apply "check auth" login only if path changeed
  // this condition is required to avoid checking auth when switching tab on the page
  // tabs are written in query params, so changing query params triggers beforeEach route guard
  if (to.path !== from.path) {

    // auth
    if (to.meta.requiresAuth) {

      try {
        let session = await Auth.currentSession()
        const authStore = useAuthStore();
        authStore.userSession = session;

        // access level
        const contactStore = useContactStore();
        const ownerStore = useOwnerStore()

        if (ownerStore.currentOwnerId) {

          let contact = await contactStore.getAuthenticatedUserContact(session.getIdToken().payload.email)
          
          // handle location_manager with single location
          if (
              to.name === 'LocationList' &&
              contact.access === ACCESS_LEVEL.LOCATION_MANAGER && 
              !isEmpty(contact.locations) && 
              Object.keys(contact.locations).length == 1
            ) {
            const location = Object.values(contact.locations)[0]
            return {
              name: "LocationDetails",
              query: {
                tab: "notifications"
              },
              params: {
                SK: location.SK,
                locale: to.params.locale
              }
            }
          }

          // handling default redirect for LISTENER role
          if (contact.access === ACCESS_LEVEL.LISTENER && to.name === 'LocationList') {
            return {
              name: 'SoloveykoBrowser',
              params: {
                locale: to.params.locale
              }
            }
          }
          
          // If access level is insufficient, redirect to owner page
          if (to.meta.allowedAccessLevels && !to.meta.allowedAccessLevels.includes(contact.access)) {
            dangerToast('Недостатній рівень прав доступу!', 'Зверніться до адміністратора вашої організації.')

            return {
              name: 'Owner',
              params: {
                locale: to.params.locale
              }
            }
          }
        }

        // Redirect to OwnerSettings for selecting owner only if "to" page is restricted by access level
        // OwnerSettings and Support pages are not access-restricted pages
        if (!ownerStore.currentOwnerId && !['OwnerSettings', 'Support'].includes(to.name)) {
          // if no owner selected, we can`t check for owner contact access level, so redirect to owner page
          dangerToast('Організацію не обрано!', 'Перейдіть до налаштувань та оберіть бажану організацію.')

          return {
            name: 'OwnerSettings',
            params: {
              locale: to.params.locale
            }
          }
        }
      }
      catch (error) {
        console.log(error)
        if (to.name !== 'SignIn') {
          return {
            name: 'SignIn', 
            params: {
              locale: to.params.locale
            }
          }
        }
      }
    }

  }
  
})

export default router
