import soloveykoApi from '@/api/soloveyko.api'
import { combinedSessionLocalStorage_getItem } from '@/helpers/storageHelpers'

class ContactApi {
  constructor() {}

  async listContactsByOwner() {
    let response = await soloveykoApi.get('/contact/byOwner/list', {
      params: {
        owner_id: combinedSessionLocalStorage_getItem("currentOwnerId"),

      }
    })
    return response.data.contacts
  }

  async getContactByEmail(email) {
    let response = await soloveykoApi.get('/contact', {
      params: {
        owner_id: combinedSessionLocalStorage_getItem("currentOwnerId"),
        email: email
      }
    })
    return response.data.contact
  }

  async createContact(contact) {
    let response = await soloveykoApi.post("/contact", {
      owner_id: combinedSessionLocalStorage_getItem("currentOwnerId"),
      ...contact
    })
    return response.data.contact
  }

  async updateContact(contact) {
    let response = await soloveykoApi.put("/contact", {
      owner_id: combinedSessionLocalStorage_getItem("currentOwnerId"),
      ...contact
    })
    return response.data.contact
  }

  async deleteContact(contactSK) {
    let response = await soloveykoApi.delete("/contact", {
      data: {
        owner_id: combinedSessionLocalStorage_getItem("currentOwnerId"),
        contact_sk: contactSK
      }
    })
    return response.data
  }

  async contactAddLocations(contactEmail, locationKeys) {
    return await soloveykoApi.put('/contact/addLocations', {
      owner_id: combinedSessionLocalStorage_getItem("currentOwnerId"),
			email: contactEmail, // SK instead of email?
      location_keys: locationKeys
    })
    .then((response) => {
      return response.data.contact
    })
  }

  async campaignAddLocationsByCustomIds(contactEmail, customIds, selectedLocations, selectedZones) {
    return await soloveykoApi.put('/contact/addLocationsByCustomIds', {
      owner_id: combinedSessionLocalStorage_getItem("currentOwnerId"),
			email: contactEmail, // SK instead of email?
      custom_ids: customIds,
      locations: selectedLocations,
      zones: selectedZones
    })
    .then((response) => {
      return response.data.contact
    })
  }
}

export default new ContactApi()
