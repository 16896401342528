<template>
  <div class="s-bg s-text-secondary font-comfortaa w-screen	h-screen flex flex-col flex-nowrap"
    @dragover.stop.prevent=""
    @drop.stop.prevent=""
  >
    <SDesktopTitleBar v-if="ElectronHelper.isElectronApp()"/>

    <!-- <div v-if="env === 'development'"
        class="flex bg-red-700 s-text-primary shrink-0 w-full justify-center" >
        <p  > This is {{ env}} environment!</p>
    </div> -->
    
    <!-- Window (viewport) size and resolution indicator (for development) -->
    <div 
      v-if="env === 'development'"
      class="fixed top-0 left-0 font-xl text-error pt-8 pl-2 z-[100] hover:hidden"
    >
      <p class="xs:hidden"> XXS</p>
      <p class="hidden xs:block sm:hidden">XS</p>
      <p class="hidden sm:block md:hidden">SM</p>
      <p class="hidden md:block lg:hidden">MD</p>
      <p class="hidden lg:block xl:hidden">LG</p>
      <p class="hidden xl:block 2xl:hidden">XL</p>
      <p class="hidden 2xl:block"> XXL</p>
      <p>{{ width + ' x ' + height  }}</p>
    </div>

    <!--content-->
    <div class="flex w-full h-full flex-1 relative">
      <div class="absolute inset-0 h-full w-full flex flex-row flex-nowrap  "
          :class="{'blur-sm brightness-150 grayscale	' : loadingHelper.isLoading || navBarStore.modalIsOpen }">
        <NavBar
          v-if="!route.meta.hideNavbar"
          @changeTheme="changeTheme"
        />

        <div class="flex flex-col h-full w-full relative lg:duration-75 overflow-auto md:overflow-hidden scroll soloveyko-scroll s-scrollbar-stable"
            :class="[route.meta.hideNavbar ? '': navBarStore.menuCollapsed ? 'lg:pl-20' :'lg:pl-80',
                    {'blur-sm brightness-50	' : navBarStore.mobileMenuOpen || navBarStore.modalIsOpen}]"
                    @scroll="onScrollHandler"

            >
          <router-view :key="$route.path"/>
          <keep-alive>
            <component :is="SPageFooterPlayer"></component>
          </keep-alive>
        </div>
        <SLoader v-if="loadingHelper.isLoading"/>
          <input @change="checkEvent"
                ref="themeCheckbox"
                type="checkbox"
                data-toggle-theme="light,dark"
                data-act-class="ACTIVECLASS"
                class="hidden"
              />
      </div>
    </div>
  </div>

</template>

<script setup>

const env = process.env.NODE_ENV
 

import { useRoute } from 'vue-router';
import {  ref, onMounted, shallowRef, defineAsyncComponent, onBeforeUnmount } from 'vue';
import ElectronHelper from '@/helpers/electronHelper';

import { useNavBarStore } from "@/store/navBarStore";
import { useDynamicHeaderStore } from "@/store/dynamicHeaderStore";

import { StatusBar, Style }  from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { NavigationBar }     from '@hugotomazi/capacitor-navigation-bar';
import { themeChange }       from 'theme-change'

import SDesktopTitleBar from './components/layout/SDesktopTitleBar.vue';
import SLoader from './components/layout/SLoader.vue';

import NavBar from "@/components/reworked/widgets/navbar/NavBar.vue";

import loadingHelper from './helpers/loadingHelper.js';

import "@/styles/palette.scss";

const route = useRoute();

const navBarStore = useNavBarStore();

const dynamicHeaderStore = useDynamicHeaderStore();

const SPageFooterPlayer = shallowRef(null);

const themeCheckbox = ref(null);

const changeTheme = () => {
  themeCheckbox.value.click();
}
const checkEvent = (event) => {
  if (!Capacitor.isNativePlatform()) {
    return;
  }

  if (event.target.checked) {
    setStatusBarStyleLight()
  }
  else {
    setStatusBarStyleDark()
  }
}

// TODO: determine if we are on mobile and only run then
const setStatusBarStyleLight = async () => {
  await StatusBar.setBackgroundColor({ color: "#f2f2f2" });
  await StatusBar.setStyle({ style: Style.Dark });
  await NavigationBar.setColor({ color: "#f2f2f2", darkButtons: true });
};

const setStatusBarStyleDark = async () => {
  await StatusBar.setBackgroundColor({ color: "#242933" });
  await StatusBar.setStyle({ style: Style.Light });
  await NavigationBar.setColor({ color: "#242933", darkButtons: false });
};


const setStartTheme = () => {

  if (!localStorage.getItem("theme")) {
    localStorage.setItem("theme", "dark");
  }

  if (Capacitor.isNativePlatform()) {
    if (localStorage.getItem("theme") === "dark") {
        setStatusBarStyleDark();
    } else {
      setStatusBarStyleLight();
    }
  }
  themeChange(false)
}


/* Window dimensions monitoring (for development) */

const width = ref(window.innerWidth);
const height = ref(window.innerHeight);

onMounted(()=> {
  setStartTheme();
  SPageFooterPlayer.value = defineAsyncComponent(() => import('./components/layout/SPageFooterPlayer.vue'));
  window.addEventListener('resize', onResizeHandler)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResizeHandler)
})

const onScrollHandler = (e) => {
  dynamicHeaderStore.onMobileScreenScroll(e.target.scrollTop)
}

const onResizeHandler = () => {
  dynamicHeaderStore.clearAllPositions()
  width.value = window.innerWidth;
  height.value = window.innerHeight;
}

</script>